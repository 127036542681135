
var $body = $('.js-body');

const body = document.querySelector('.js-body'),
    //   thisPage = body.dataset.page,
    //   site = body.dataset.site,
      header = document.querySelector(".js-header"),
      headerInner = document.querySelector(".js-header-inner"),
      pageTitleCtrls = document.querySelector(".js-page-title-ctrl"),
      carousels = document.querySelectorAll(".js-carousel"),
      readMoreButton = document.querySelector(".js-read-more"),
      homeHero = document.querySelector(".js-hero"),
      heroAlt = document.querySelector(".js-hero-alt"),
      teamBio = document.querySelector(".js-team-bio");

let width = window.innerWidth,
    height = window.innerWidth,
    scrollPos = 0,
    // headerTriggerPoint = window.innerHeight / 2;
    headerTriggerPoint = header.clientHeight * 3;
