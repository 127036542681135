window.addEventListener("resize", () => {

    if (typeof readMoreButton != "undefined" && readMoreButton != null) {
        stTarget = heroAlt.clientHeight;
    }

    if (window.innerWidth != width) {
        width = window.innerWidth;
        height = window.innerWidth;
        
        if (typeof homeHero != "undefined" && homeHero != null) {
            heroSize();
        }

        //hero alt size
        if (typeof heroAlt != "undefined" && heroAlt != null) {
            heroAltSize();
        }
    }
});
