// Codekit -->
// @codekit-prepend "global/vars.js"
// @codekit-prepend "utilities/rhparallax.js"
// @codekit-prepend "utilities/loadIn.js"
// @codekit-prepend "utilities/debounce.js"
// @codekit-prepend "utilities/ajax.js"

// @codekit-prepend "components/header.js"
// @codekit-prepend "components/menu.js"

// @codekit-prepend "components/imageCarousel.js"
// @codekit-prepend "components/readMore.js"
// @codekit-prepend "components/teamBios.js"
// @codekit-prepend "components/pageLoad.js"
// @codekit-prepend "components/heroSize.js"
// @codekit-prepend "components/lazyloadVideos.js"

// @codekit-append "global/resize.js"

//fire funcitons
//header
window.addEventListener("scroll", debounce(headerReveal));

//menu
menu();

//hero size
if (typeof homeHero != "undefined" && homeHero != null) {
    heroSize();
}

//hero alt size
if (typeof heroAlt != "undefined" && heroAlt != null) {
    heroAltSize();
}

//carousels
if (typeof carousels != "undefined" && carousels != null) {
    imageCarouselInit();
}

//read more
if (typeof readMoreButton != "undefined" && readMoreButton != null) {
    let stTarget = heroAlt.clientHeight;
    readMore();
}

//team bios
if (typeof teamBio != "undefined" && teamBio != null) {
    teamBioAnimate();
}

//page load
if (typeof homeHero != "undefined" && homeHero != null) {
    pageLoad();
}


//on ready
$(document).ready(function () {
    $body.rhLoadIn();

    $(".parallax").rhParallax({
        parallaxRatio: 1.4
    });

    //images loaded
    $(".bg-img-load").imagesLoaded({ background: ".bgImgLoad" }, function () {
        $(".bg-img-load").animate({ opacity: "1" }, 300);
    });

    $(".img-load").imagesLoaded(function () {
        $(".img-load").animate({ opacity: "1" }, 300);
    });

}); // end doc ready

//This pauses videos on page refresh
//Ensures no flash of white
$(window).on("beforeunload", function () {
    // console.log('PAUSE VIDEO');
    $(".vid-unload").each(function () {
        $(this).get(0).pause();
    });

});

