document.addEventListener("DOMContentLoaded", function () {
    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy-video"));
    if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver(
            function (entries) {
                entries.forEach(function (video) {
                    if (video.isIntersecting) {
                        for (var source in video.target.children) {
                            var videoSource = video.target.children[source];
                            if (
                                typeof videoSource.tagName === "string" &&
                                videoSource.tagName === "SOURCE"
                            ) {
                                videoSource.src = videoSource.dataset.src;
                            }
                        }
                        video.target.load();
                        video.target.classList.remove("lazy-video");
                        lazyVideoObserver.unobserve(video.target);
                    }
                });
            },
            { rootMargin: "0px 0px 500px 0px" });
        lazyVideos.forEach(function (lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
        });
    }
});
