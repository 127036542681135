function imageCarouselInit() {

    carousels.forEach((carousel) => {

        //set up new flickity for each carousel block
        var flkty = new Flickity(carousel, {
            // options
            cellAlign: "center",
            contain: true,
            accessibility: true,
            prevNextButtons: false,
            imagesLoaded: true,
            lazyLoad: 3,
            pageDots: false,
            setGallerySize: false,
            selectedAttraction: 0.2,
            friction: 0.9,
            resize: true,
            wrapAround: true,
            on: {
                ready: function (index) {
                    console.log("ready");

                },
                settle: function (index) {
                    // console.log('settle');

                    //cache slides
                    const slides = carousel.querySelectorAll(".js-carousel-slide");

                    //get active slide
                    let activeSlide = slides[index];

                    //find selectors
                    const activeFigCaption = activeSlide.querySelector(".js-figcaption");

                    if (activeFigCaption) {
                        //add active class to slide figcaption
                        activeFigCaption.classList.add("is-active");
                    }
                },
                change: function (flkty, cellNumber) {
                    // console.log('change');

                    //cache slides 
                    const slides = carousel.querySelectorAll(".js-carousel-slide");

                    slides.forEach((slide) => {
                        //find selector
                        const changedFigCaption = slide.querySelector(".js-figcaption");

                        if (changedFigCaption) {
                            //remove active class to slide figcaption
                            changedFigCaption.classList.remove("is-active");
                        }
                    });
                }
            }
        });

        //custom nav
        const carouselParent = carousel.parentNode,
        prevButton = carouselParent.querySelector('.js-carousel-prev'),
        nextButton = carouselParent.querySelector('.js-carousel-next'),
        prevCursor = carouselParent.querySelector('.js-cursor-prev'),
        nextCursor = carouselParent.querySelector('.js-cursor-next');

        //click through slides
        prevButton.addEventListener( 'click', function() {
            flkty.previous();
        });

        nextButton.addEventListener( 'click', function() {
            flkty.next();
        });

        //mouse enter
        prevButton.addEventListener('mouseenter', function() {
            prevCursor.classList.add('is-active');
        });

        nextButton.addEventListener('mouseenter', function() {
            nextCursor.classList.add('is-active');
        });

        //mouse leave
        prevButton.addEventListener('mouseleave', function() {
            prevCursor.classList.remove('is-active');
        });

        nextButton.addEventListener('mouseleave', function() {
            nextCursor.classList.remove('is-active');
        });

        //mouse move
        prevButton.addEventListener('mousemove', function(e){
            var x = e.clientX;
            var y = e.clientY;
            prevCursor.style.left = x + "px";
            prevCursor.style.top = y + "px";
        });
    
        nextButton.addEventListener('mousemove', function(e){
            var x = e.clientX;
            var y = e.clientY;
            nextCursor.style.left = x + "px";
            nextCursor.style.top = y + "px";
        });

        
    });
}
