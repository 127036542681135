function heroSize() {
    width = window.innerWidth;
    height = window.innerHeight;

    homeHero.style.height = height + "px";

    if (window.matchMedia("(orientation: portrait)").matches) {
        if (width < 860) {
            homeHero.style.height = height + "px";
        } else {
            homeHero.style.height = "";
        }
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
        // you're in LANDSCAPE mode
        if (width > 860) {
            homeHero.style.height = height + "px";
        } else {
            homeHero.style.height = "";
        }
    }
}

function heroAltSize() {

    width = window.innerWidth;
    height = window.innerHeight;

    heroAlt.style.height = height + "px";

    if (window.matchMedia("(orientation: portrait)").matches) {
        if (width < 860) {
            heroAlt.style.height = height + "px";
        } else {
            heroAlt.style.height = "";
        }
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
        // you're in LANDSCAPE mode
        if (width > 860) {
            heroAlt.style.height = height + "px";
        } else {
            heroAlt.style.height = "";
        }
    }
}
