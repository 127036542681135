/* ==========================================================================
  Ajax
	-----------------------------------------------
	- open class for elements = .js-ajax-start
	- target ajax item(s) = '#ajaxTarget'
	- scroll on .ajax (overflow: hidden)
	- call loadIn on #ajaxContainer
	
	- 	$(‘#ajaxContainer’).rhLoadIn({
       		ajax:true
		})
    
    - 	$('.parallax').rhParallax({
			parallaxRatio:1.4,
			ajax:true,
			selector:'#ajaxContainer'
		});


  ========================================================================== */

	var $ajaxContainer = $('#ajaxContainer'),
		$closeAjaxButton = $('#closeAjaxButton'),
		$ajaxHolder = $('#ajaxHolder'),
		ajaxOpen = false,
		originalHref,
		ajaxHref,
		$loader = $('#loader')
		;

	//ajax for cards
	$(document).on("click", '.js-ajax-start', function(e) {
	    e.preventDefault();

	    $loader.show();

	  	ajaxHref = $(this).attr('href');

	    if (ajaxOpen == false) {

	      ajaxOpen = true;

	      originalHref = window.location.href;

	  		$body.addClass('stop-scrolling');

	  		gsap.to($ajaxContainer, 0.4, {display: 'block', autoAlpha: 1, 'top':'0', ease:Power4.easeInOut,onComplete:openAjax, onCompleteParams: [ajaxHref]});

	  		gsap.to($closeAjaxButton, 0.3, {'display':'block', autoAlpha: 1, ease:Power4.easeInOut, delay:0.5});

	    }

	    history.pushState({}, '', ajaxHref);

	});


	//close ajax
	$closeAjaxButton.on('click', function(e) {
	    e.preventDefault();

	    ajaxOpen = false;

	  	$body.removeClass('stop-scrolling');

	  	gsap.to($ajaxContainer, 0.4, {display: 'none', autoAlpha: 0, 'top':'100%', ease:Power4.easeInOut,onComplete: emptyAjax});

	  	$closeAjaxButton.hide();

	  	history.pushState({}, '', originalHref);
	});

	//history pop state
	window.onpopstate = function() {

		$body.removeClass('stop-scrolling');

		if (ajaxOpen == true ) {

			ajaxOpen = false;

			gsap.to($ajaxContainer, 0.4, {display: 'none', autoAlpha: 0, 'top':'100%', ease:Power4.easeInOut,onComplete: emptyAjax});

			$closeAjaxButton.hide();

			history.pushState({}, '', originalHref);

		}

	};


function openAjax(ajaxHref) {

	$ajaxHolder.load(ajaxHref + " #ajaxTarget", function() {

        $loader.fadeOut();

        const bioTl = gsap.timeline({ delay: 0.3, paused:true });
        
        bioTl.to(".js-team-bio-title", 0.3, { left: 0, ease:Power4.easeInOut })
             .to(".js-team-bio-position", 0.3, { autoAlpha: 1, ease:Power4.easeInOut})
             .to(".js-team-bio-content", 0.3, { autoAlpha: 1, y: 0, ease:Power4.easeInOut}, "-=0.3");
    

		$ajaxContainer.imagesLoaded(function(){

            playBioTimelines(bioTl);

			$('.team-bio__image').animate({'opacity': 1}, 300);

			//refire functions here
			//	$('.parallax').rhParallax({
			//		parallaxRatio:1.4,
			//		ajax:true,
			//		selector:'#ajaxContainer'
			//	});

			$('#ajaxContainer').rhLoadIn({
		        ajax:true
		   });

		});

	});

}

function emptyAjax() {
	$ajaxHolder.empty();
}

function playBioTimelines(bioTl) {
    bioTl.pause();
    bioTl.restart();
}