function teamBioAnimate() {
    const bioTitle = document.querySelector('.js-team-bio-title'),
        bioPosition = document.querySelector('.js-team-bio-position'),
        bioContent = document.querySelector('.js-team-bio-content');
        // bioImage = document.querySelector('.js-team-bio-content')

    //team bio timeline
    const bioTl = gsap.timeline({ delay: 0.3, paused:true });
        
    bioTl.to(bioTitle, 0.3, { left: 0, ease:Power4.easeInOut })
         .to(bioPosition, 0.3, { autoAlpha: 1, ease:Power4.easeInOut})
         .to(bioContent, 0.3, { autoAlpha: 1, y: 0, ease:Power4.easeInOut}, "-=0.3");

    window.addEventListener("load", () =>{
        playBioTimelines(bioTl);
    });
    
}

function playBioTimelines(bioTl) {
    bioTl.pause();
    bioTl.restart();
}