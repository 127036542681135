function menu() {
    
    const body = document.querySelector(".js-body"),
        menuToggle = [...document.querySelectorAll(".js-menu-button")],
        header = document.querySelector(".js-header"),
        mobileMenu = document.querySelector(".js-menu"),
        staggerItems = document.querySelectorAll('.js-stagger'),
        stopScrollingClass = "stop-scrolling",
        isActiveClass = "is-active"
    ;

    let isActive = false;

    //open menu timeline
    const menuOpenTl = gsap.timeline({ paused:true });

    menuOpenTl.call(addIsActiveClass, [header, isActiveClass], "+=0")
        .to(mobileMenu, 0.3, { autoAlpha: 1, ease: Power1.easeInOut } )
        .to(staggerItems, { y: 0, autoAlpha: 1, stagger: {  grid: [1,5],
            from: "end",
            axis: "y",
            amount: 1}}, "-=0.2");

    //close menu timeline
    const menuCloseTl = gsap.timeline({ paused:true });

    menuCloseTl.to(staggerItems, 0.3, { autoAlpha: 0, ease: Power4.easeInOut })
        .to(mobileMenu, 0.3, { autoAlpha: 0, ease: Power1.easeInOut }, "-=0.3")
        .call(removeIsActiveClass, [header, isActiveClass], "-=0.3");

    //bind events
    menuToggle.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            e.preventDefault();
            isActive = !isActive;

            if (isActive) {
                body.classList.add(stopScrollingClass);
                openMenuAnimations(menuOpenTl,menuCloseTl, btn);
            } else {
                closeMenuAnimations(menuOpenTl,menuCloseTl, btn);
                body.classList.remove(stopScrollingClass);
            }
        });
    });
}

 //functions
function openMenuAnimations(menuOpenTl,menuCloseTl) {
    menuCloseTl.pause();
    menuOpenTl.restart();
}

function closeMenuAnimations(menuOpenTl,menuCloseTl) {
    menuOpenTl.pause();
    menuCloseTl.restart();
}

function addIsActiveClass(header, isActiveClass) {
    header.classList.add(isActiveClass);
}

function removeIsActiveClass(header, isActiveClass) {
    header.classList.remove(isActiveClass);
}

