// function headerReveal() {

//     const isVisibleClass = "is-visible",
//           isHiddenClass = "is-hidden";

//     let windowY = window.scrollY;

//     if (windowY > scrollPos) {
//         // Scrolling DOWN
//         //console.log("scroll-down");

//         if (windowY > headerTriggerPoint) {
//             header.classList.remove("is-at-top");
//             header.classList.add("is-hidden");
//             header.classList.add("is-sticky");
//             header.classList.remove("is-visible");
//         }

//     } else {
//         // Scrolling UP
//         //console.log("scroll-up");

//         header.classList.add("is-visible");
//         header.classList.remove("is-hidden");

//         if (windowY < headerTriggerPoint) {

//             header.classList.add("is-at-top");
//             header.classList.remove("is-sticky");
//             header.classList.remove("is-visible");

//         }

//     }

//     scrollPos = windowY;

// }

// function headerReveal() {
//     let windowY = window.scrollY;

//     if (windowY > scrollPos) {
//         // Scrolling DOWN
//         //console.log("scroll-down");

//         if (windowY > headerTriggerPoint) {
//             header.classList.add("is-hidden");
//             header.classList.remove("is-at-top");
//         }
//     } else {
//         // Scrolling UP
//         //console.log("scroll-up");

//         header.classList.remove("is-hidden");

//         if (windowY > headerTriggerPoint) {
//             header.classList.add("fill-bg");
//         } else if (windowY <= 0) {
//             header.classList.remove("fill-bg");
//             header.classList.add("is-at-top");
//         }
//     }

//     scrollPos = windowY;
// }


function headerReveal() {
    let windowY = window.scrollY;

    if (windowY > scrollPos) {
        // Scrolling DOWN
        //console.log("scroll-down");

        if (windowY > header.clientHeight && windowY < headerTriggerPoint) {
            header.classList.add("is-hidden");
        }

        if (windowY > headerTriggerPoint) {
            headerInner.classList.remove("is-visible");
            header.classList.remove("is-at-top");
            header.classList.add("is-sticky");
        }
    } else {
        // Scrolling UP
        //console.log("scroll-up");

        header.classList.remove("is-hidden");
        headerInner.classList.add("is-visible");

        if (windowY > headerTriggerPoint) {
            headerInner.classList.add("fill-bg");
        } else if (windowY < headerTriggerPoint) {
            header.classList.remove("is-sticky");
            header.classList.add("is-at-top");
            headerInner.classList.remove("fill-bg");
        }
    }

    scrollPos = windowY;
}
