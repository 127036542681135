function pageLoad() {
    //loading animation
    const loaderWipe = document.querySelector(".js-loading-wipe-1"),
          pageLoadTl = gsap.timeline({onComplete: loadingWipe});

    pageLoadTl.to(loaderWipe, 2, { x: "100%", ease: Expo.easeIn });
}

function loadingWipe() {
    const loading = document.querySelector(".js-loading"),
          hero = document.querySelector(".js-hero"),
          heroContent = document.querySelector(".js-hero-content"),
          loaderWipe2 = document.querySelector(".js-loading-wipe-2"),
          loaderText = document.querySelector(".js-loader-title");

    const wipeTl = gsap.timeline({paused: true});

    wipeTl.to(loaderText, 0.5, { color: "#ff0001", ease: Power1.easeInOut })
          .to(loaderWipe2, 0.7, { xPercent: 100, ease: Expo.easeInOut }, "-=0.3")
          .to(loading, 0.4, { xPercent: 100, ease: Power4.easeInOut })
          .to(heroContent, 0.5, { marginTop: 0, autoAlpha: 1, ease: Power2.easeInOut });

    // if (hero.dataset.imgvid == 'video') {
    //     const heroVideo = document.querySelector(".js-hero-video");


    //     if ( heroVideo.readyState >= 3 ) {
    //         //console.log('video is ready');
    //         wipeTl.restart();
    //     }

    // } else {
    //     const heroImage = document.querySelector(".js-hero-image");

    //     $(heroImage).imagesLoaded(function () {
    //         // console.log('hero image loaded');
    //         wipeTl.restart();
    //     });
    
    // }

    wipeTl.restart();
}